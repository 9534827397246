
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'ElMessiri-Medium', 'ElMessiri-Bold', 'ElMessiri-Regular', 'ElMessiri-SemiBold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

h1, p, ul {
  font-family: ElMessiri-Regular;
  direction: rtl;
  margin: 0;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  'ElMessiri-Medium', 'ElMessiri-Bold', 'ElMessiri-Regular', 'ElMessiri-SemiBold', monospace;
}

.swal-direction {
  direction: rtl;
}
* {
  -webkit-tap-highlight-color: transparent;
}
.add-image{
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-text , .two-text{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;

}
.quill {
  min-height: 20rem;
  background-color: white;
}


.add-image img{
  height: auto ;
  object-fit: contain;
}


 
  .text1, .text2{
  width: 100%;
  }

  @media only screen and (max-width: 1024px) {
  .image-text  {
    flex-wrap: wrap;
    }
  .two-text{
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .add-image{
    width: 100%;
  
  }

}

.ql-align-center{
  text-align: center;
}


.ql-align-left{
  text-align: left;
}

.ql-align-right{
  text-align: right;
}


@media only screen and (max-width: 768px) {

  .add-image img{
 
    max-width: 100% !important;
  }
}